import React, { useEffect, useState } from "react"

var ImgWidth="auto";
var ImageHeight="auto";
const isBrowser = typeof window !== "undefined";

const formImageSrcSet = params => {
  const keys = Object.keys(params.transforms),
    keysLength = keys.length
  let imageSrcSet = {
    src: params.images[keys[keysLength - 1]],
    srcSet: "",
    sizes: ""
  }
  for (let index = 0; index < keysLength; index++) {
    let width = keys[index].substr(0, keys[index].search(/[a-z]/i))
    imageSrcSet.srcSet += `${params.images[keys[index]]} ${width}w, `
    imageSrcSet.sizes += `${params.transforms[keys[index]]} ${width}px, `
    let condiWidth=parseInt(params.transforms[keys[index]].replace(/[^0-9]/g,''))
    //Load image width and height for page raing improvements
    if(ImgWidth === "auto" && ImageHeight === 'auto' && isBrowser){
      if(window.innerWidth <= parseInt(condiWidth)){
        ImgWidth = keys[index].split('x')[0];
        ImageHeight = keys[index].split('x')[1];
      }
    }   
  }
  //If size not get select load default size
  if(ImgWidth === "auto" && ImageHeight === 'auto' && isBrowser){
      ImgWidth = keys[keysLength-1].split('x')[0];
      ImageHeight = keys[keysLength-1].split('x')[1];
  }
  if (params.isTeamParam) {
    // console.log(45678, params.transforms, params.images, imageSrcSet)
  }
  return imageSrcSet;
}
const SrcSetImage = ({ props }) => {
  const [Imgs, SetImgs] = useState({});
  useEffect(() => {
    if (props.images) {
      const ImageSrcSet = formImageSrcSet(props)
      SetImgs(ImageSrcSet);
    } else {
      SetImgs({});
    }
  }, [props]);
  let lazyLoading = "lazy";
  if(props?.lazyLoading && props.lazyLoading == "false"){
    lazyLoading = "";
  }
  return <img loading={lazyLoading} {...Imgs}
  {...props.attr}
  // width= {ImgWidth}
  // height= {ImageHeight}
   />
}
export default SrcSetImage


